import { ControllerFlowAPI, IPlatformAPI } from '@wix/yoshi-flow-editor';
import { BIRole, userRoleToBIRole } from '../utils/bi';
import { CommonBIData } from './bi';

const memberRoles: BIRole[] = ['owner', 'member'];

export class CommonBIDataFromFlowAPI implements CommonBIData {
  constructor(private flowAPI: ControllerFlowAPI) {}

  // Turns out platformAPIs.bi object may contain many more useful properties.
  private get bi(): Partial<NonNullable<IPlatformAPI['bi']>> {
    return this.flowAPI.controllerConfig.platformAPIs.bi ?? {};
  }
  private get user() {
    return this.flowAPI.controllerConfig.wixCodeApi.user.currentUser;
  }

  get appInstanceId() {
    return this.flowAPI.controllerConfig.appParams.instanceId;
  }
  get metaSiteId() {
    return this.bi.metaSiteId;
  }
  get biToken() {
    return this.bi.biToken;
  }
  get role() {
    return userRoleToBIRole(this.user.role);
  }
  get siteMemberId() {
    return this.bi.siteMemberId ?? (memberRoles.includes(this.role) ? this.user.id : undefined);
  }
  get visitorId() {
    return this.bi.visitorId;
  }
}
