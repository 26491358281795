import { MembershipApi } from '@wix/ambassador-membership-api/http';
import { ApiHeaders } from './headers';

type MembershipApiT = ReturnType<typeof MembershipApi>;
type PlansService = ReturnType<MembershipApiT['PlansService']>;

export function createPlansWriteApi(headers: ApiHeaders, baseUrl = '') {
  return new PlansWriteApi(headers, MembershipApi(baseUrl + '/_api/paid-plans').PlansService());
}

export class PlansWriteApi {
  constructor(protected headers: ApiHeaders, protected plansService: PlansService) {}

  clearPrimaryPlan() {
    return this.plansService(this.headers).makeNoPlanPrimary({});
  }
}
